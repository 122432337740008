import React, { useState, useEffect, useContext } from 'react';
import Banner from './Banner';
import axios from '../../../utils/axios';
import { AuthContext } from '../../../contexts/JWTContext';
import UnverifiedUniversityDialog from './UnverifiedUniversityDialog';
import UniversityOnboardingBanner from './Onboarding/University/UniversityOnboardingBanner';




const fetchActiveBanners = async () => {
    try {
        console.log('fetching banners');
        const response = await axios.get('api/v1/auxiliary/active-banners/');
        return response.data;
    } catch (error) {
        console.error('Error fetching banners:', error);
        return [];
    }
};

const dismissBanner = async (bannerId) => {
    try {
        await axios.post(`/api/v1/auxiliary/dismiss-banner/${bannerId}/`);
    } catch (error) {
        console.error('Error dismissing banner:', error);
    }
};

const BannerContainer = () => {
  const [banners, setBanners] = useState([]);
  const { isAuthenticated, user } = useContext(AuthContext);
  console.log('Banner COmp');

  useEffect(() => {
    const getBanners = async () => {
      if (isAuthenticated && user) {
        const lastBannerFetchTime = localStorage.getItem('lastBannerFetchTime');
        const lastLoginTime = localStorage.getItem('lastLoginTime');

        if (!lastBannerFetchTime || (lastLoginTime && new Date(lastLoginTime) > new Date(lastBannerFetchTime))) {
          try {
            const fetchedBanners = await fetchActiveBanners();
            setBanners(fetchedBanners);
            localStorage.setItem('lastBannerFetchTime', new Date().toISOString());
          } catch (error) {
            console.error('Error fetching banners:', error);
          }
        }
      }
    };

    getBanners();
  }, [isAuthenticated, user]);

  const handleDismiss = async (bannerId) => {
    try {
      await dismissBanner(bannerId);
      setBanners(banners.filter(banner => banner.id !== bannerId));
    } catch (error) {
      console.error('Error dismissing banner:', error);
    }
  };

  return (
    <> 
    <UnverifiedUniversityDialog />
    <UniversityOnboardingBanner />
      {banners.map(banner => (
        <Banner key={banner.id} banner={banner} onDismiss={handleDismiss} />
      ))}
    </>
  );
};

export default BannerContainer;