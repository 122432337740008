import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'university/signup',
          element: (
            <GuestGuard>
              <UniversitySignup />
            </GuestGuard>
          ),
        },
        {
          path: 'student/login',
          element: (
            <GuestGuard>
              <StudentLogin />
            </GuestGuard>
          ),
        },
        {
          path: 'university/login',
          element: (
            <GuestGuard>
              <UniversityLogin />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'create-password', element: <NewUserResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'setup-user', element: <SetupPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        // { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { element: <Navigate to="/dashboard/crossing" replace />, index: true },
        { path: 'crossing', element: <Crossing /> },
        // { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductEdit /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },
    // FlyOver Routes Routes
    {
      path: '/student/dashboard',
      element: (
        <RoleBasedGuard roles={['student']}>
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [
        { path: 'app', element: <StudentDashboard /> },
        {
          path: 'career-explorer',
          children: [
            {
              path: '',
              element: <CareerExplorer />,
            },
            {
              path: 'career-questions',
              element: <CareerExplorerQuestions />,
            },
            {
              path: 'suggested-position',
              element: <SuggestedPosition />,
            },
            {
              path: 'past-results',
              element: <CareerExplorerHistory />,
            },
          ],
        },
        {
          path: 'career-track',
          children: [
            {
              path: 'home',
              element: (
                <RoleBasedGuard roles={['student']} slugCode="student-career-track">
                  <CareerTrackDashboard />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'interview-qa',
              element: (
                <RoleBasedGuard roles={['student']} slugCode="student-career-track">
                  <InterviewQA />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'mbti-test',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard roles={['student']} slugCode="student-career-track">
                      <MbtiTest />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'results',
                  element: (
                    <RoleBasedGuard roles={['student']} slugCode="student-career-track">
                      <MbtiResults />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'results/:id',
                  element: (
                    <RoleBasedGuard roles={['student']} slugCode="student-career-track">
                      <MbtiResultView />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },

            {
              path: 'mock-interview',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard roles={['student']} slugCode="student-career-track">
                      <MockInterview />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'results',
                  element: (
                    <RoleBasedGuard roles={['student']} slugCode="student-career-track">
                      <MockInterviewResults />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'results/:id',
                  element: (
                    <RoleBasedGuard roles={['student']} slugCode="student-career-track">
                      <MockInterviewResultView />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'roadmap',
              element: <CTRoadmap />,
            },
          ],
        },
        {
          path: 'workshops',
          children: [
            {
              path: 'view',
              element: (
                <RoleBasedGuard roles={['student']} slugCode="student-list-workshop">
                  <StudentWorkshops />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view/:id',
              element: (
                <RoleBasedGuard roles={['student']} slugCode="student-list-workshop">
                  <StudentWorkshopPostView />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'feed',
          children: [
            {
              path: 'view',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard roles={['student']} slugCode="student-list-feed">
                      <StudentFeed />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id',
                  element: (
                    <RoleBasedGuard roles={['student']} slugCode="student-list-feed">
                      <StudentFeedPostView />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'jobs',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard roles={['student']} slugCode="student-jobs">
                  <Jobs />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view/:id',
              element: (
                <RoleBasedGuard roles={['student']} slugCode="student-jobs">
                  <StudentJobsView />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'applied',
              element: <JobsApplied />,
            },
          ],
        },
        {
          path: 'internships',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard roles={['student']} slugCode="student-internships">
                  <Internships />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view/:id',
              element: (
                <RoleBasedGuard roles={['student']} slugCode="student-internships">
                  <InternshipView />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'events',
          children: [
            {
              path: 'view',
              element: (
                <RoleBasedGuard roles={['student']} slugCode="student-events">
                  <Events />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view/:id',
              element: <EventView />,
            },
          ],
        },
        {
          path: 'opportunities',
          children: [
            {
              path: 'view',
              element: <Opportunities />,
            },
            {
              path: 'view/:id',
              element: <OpportunityView />,
            },
          ],
        },
        { path: 'hiring-drive', element: <HiringDrive /> },
        { path: 'setup', children: [{ path: 'onboarding', element: <Onboarding /> }] },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/student/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <StudentProfile /> },
            { path: 'profile/edit', element: <EditStudentProfile /> },
            // { path: 'cards', element: <UserCards /> },
            // { path: 'list', element: <UserList /> },
            // { path: 'new', element: <UserCreate /> },
            // { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <StudentUserAccount /> },
            { path: 'test', element: <Tests /> },
            {
              path: 'resume',
              children: [
                { element: <ResumeList />, index: true },
                // {path: 'new', element: <ResumeNew />},
                { path: 'edit/:id', element: <ResumeEdit /> },
              ],
            },
          ],
        },
        // { path: 'user', element: <User /> },
        // {path: 'openings', element: <JobView />},
        // {path: 'openings/view/:id',  element: <SingleJobView /> },
        // {path: 'events', element: <EventsView />},
        // {path: 'events/view/:id', element: <SingleEventView />}
      ],
    },

    // Student Test Route
    { path: 'student-test', element: <StudentTest /> },
    { path: 'student-test-instruction', element: <TestInstruction /> },

    // University Routes
    {
      path: '/university/dashboard',
      element: (
        <RoleBasedGuard roles={['university']}>
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [
        { path: 'app', element: <UniversityStaffDashboard /> },
        {
          path: 'workshops',
          children: [
            {
              path: 'view',
              element: (
                <RoleBasedGuard roles={['university']} slugCode="university-list-workshop">
                  <UniWorkshops />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view/:id',
              element: (
                <RoleBasedGuard roles={['university']} slugCode="university-list-workshop">
                  <UniWorkshopPostView />,
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard roles={['university']} slugCode="university-list-workshop">
                  <UniWorkshopNewPost />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit/:id',
              element: (
                <RoleBasedGuard roles={['university']} slugCode="university-list-workshop">
                  <UniWorkshopEditPost />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        {
          path: 'events',
          children: [
            {
              path: 'view',
              element: (
                <RoleBasedGuard roles={['university']} slugCode="university-events">
                  <UniEvents />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view/:id',
              element: (
                <RoleBasedGuard roles={['university']} slugCode="university-events">
                  <UniEventsView />,
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard roles={['university']} slugCode="university-events">
                  <UniEventForm />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'edit/:id',
              element: (
                <RoleBasedGuard roles={['university']} slugCode="university-events">
                  <UniEventPostEdit />,
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'feed',
          children: [
            {
              path: 'view',
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard roles={['university']} slugCode="university-list-feed">
                      <UniFeed />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':id',
                  element: (
                    <RoleBasedGuard roles={['university']} slugCode="university-list-feed">
                      <UniFeedPostView />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'edit/:id',
              element: (
                <RoleBasedGuard roles={['university']} slugCode="university-list-feed">
                  <UniFeedEditPost />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard roles={['university']} slugCode="university-list-feed">
                  <UniFeedNewPost />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'hiring-drive',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard roles={['university']} slugCode="university-hiring-drive">
                  <HiringDriveUniversity />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard roles={['university']} slugCode="new-university-hiring-drive">
                  <NewHiringDriveUniversity />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/edit',
              element: (
                <RoleBasedGuard roles={['university']} slugCode="edit-university-hiring-drive">
                  <EditHiringDrive />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'add/student',
          element: (
            <RoleBasedGuard roles={['university']} slugCode="university-add-student">
              <UniversityAddStudent />
            </RoleBasedGuard>
          ),
        },

        {
          path: 'edit/student/:id',
          element: (
            <RoleBasedGuard roles={['university']} slugCode="university-add-student">
              <UniversityEditStudent />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'student/bulk-add',
          element: (
            <RoleBasedGuard roles={['university']} slugCode="university-add-student">
              <UniversityBulkAddStudent />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'student/academic-structure',
          element: (
            <RoleBasedGuard roles={['university']}>
              <AcademicStructure />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'student/academic-structure/course-manager',
          element: (
            <RoleBasedGuard roles={['university']}>
              <CourseManager />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'student/academic-structure/student-progression',
          element: (
            <RoleBasedGuard roles={['university']}>
              <StudentProgression />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'groups',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard roles={['university']} slugCode="university-groups">
                  <GroupsView />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard roles={['university']} slugCode="new-university-group">
                  <NewGroup />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        {
          path: 'student-activity',
          element: (
            <RoleBasedGuard roles={['university']} slugCode="university-student-activity">
              <StudentActivity />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'student-details',
          element: (
            <RoleBasedGuard roles={['university']} slugCode="university-student-details">
              <StudentDetails />
            </RoleBasedGuard>
          ),
        },

        {
          path: 'tests',
          children: [
            {
              path: 'list',
              element: <UniStudentTest />,
            },
            {
              path: 'view/:id',
              element: <UniStudentTestView />,
            },
            {
              path:'student/result',
              element:<UniStudentDetailedResult/>
            },
            {
              path:'schedule',
              element:<UniScheduleTest/>
            },
            {
              path:'schedule-result',
              element:<TestCreated/>
            }
          ],
        },

        {
          path: 'jobs',
          children: [
            {
              path: 'list',
              element: (
                <RoleBasedGuard roles={['university']} slugCode="university-jobs">
                  <UniversityJobs />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'jobs/approval',
          element: (
            <RoleBasedGuard roles={['university']} slugCode="university-jobs-approval">
              <JobsApproval />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'recruiterhub/company/list',
          element: (
            <RoleBasedGuard roles={['university']} slugCode="university-rh-company-list">
              <RHCompanyList />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'recruiterhub/messages',
          element: (
            <RoleBasedGuard roles={['university']} slugCode="university-rh-messages">
              <RHMessages />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'user',
          children: [{ path: 'account', element: <UniveristyUserAccount /> }],
        },
        // { path: 'jobs', element: <Jobs /> },
        // { path: 'events', element: <Events /> },
        // { path: 'user', element: <User /> },
        // {path: 'openings', element: <JobView />},
        // {path: 'openings/view/:id',  element: <SingleJobView /> },
        // {path: 'events', element: <EventsView />},
        // {path: 'events/view/:id', element: <SingleEventView />}
      ],
    },

    // Company Routes
    {
      path: '/company/dashboard',
      element: (
        <RoleBasedGuard roles={['company']}>
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [
        // { path: 'app', element: <GeneralApp /> },
        { path: 'app', element: <CompanyDashboard /> },
        { path: 'admin/test/new', element: <AdminCreateTest /> },
        { path: 'admin/test/list', element: <TestList /> },
        // { path: 'admin/test/question/:id', element: <AddQuestions /> },
        { path: 'admin/test/question/new', element: <AddQuestions /> },
        { path: 'admin/test-question/list/:id', element: <TestQuestionList /> },
        { path: 'admin/test-question-options/list/:id', element: <OptionsList /> },
        { path: 'admin/test-question/list', element: <QuestionList /> },
        { path: 'admin/test/edit/:id', element: <AdminTestEdit /> },
        { path: 'admin/test-question/edit/:id', element: <AdminTestQuestionEdit /> },
        {path: 'admin/test-builder', element: <TestBuilder />},
        {
          path: 'admin/jobs/new',
          element: (
            <RoleBasedGuard roles={['company']} slugCode="admin-job-pages">
              <AdminCreateJob />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'admin/jobs/list',
          element: (
            <RoleBasedGuard roles={['company']} slugCode="admin-job-pages">
              <AdminJobList />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'admin/career-track/new',
          element: (
            <RoleBasedGuard roles={['company']} slugCode="admin-career-track">
              <AdminCareerTrack />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'admin/career-track/list',
          element: (
            <RoleBasedGuard roles={['company']} slugCode="admin-career-track">
              <CareerTrackList />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'admin/career-track/edit/:id',
          element: (
            <RoleBasedGuard roles={['company']} slugCode="admin-career-track">
              <EditCareerTrack />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'admin/career-track/edit-pathway/:id',
          element: (
            <RoleBasedGuard roles={['company']} slugCode="admin-career-track">
              <EditCareerPathway />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'jobs',
          children: [
            { path: 'new', element: <AddJob /> },
            { path: 'list', element: <JobsList /> },
            { path: 'edit/:name', element: <EditJob /> },
            {
              path: 'applicants/:id',
              element: (
                <RoleBasedGuard roles={['company']}>
                  <ApplicantsView />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'user',
          children: [{ path: 'account', element: <CompanyUserAccount /> }],
        },
        // { path: 'jobs/list', element: <JobsList /> },
        // {
        //   path: 'admin/jobs/new',
        //   element: (
        //     <RoleBasedGuard roles={['company']} slugCode="admin-job-pages">
        //       <AdminCreateJob />
        //      </RoleBasedGuard>
        //   ),
        // },
        // {
        //   path: 'admin/jobs/list',
        //   element: (
        //     <RoleBasedGuard roles={['company']} slugCode="admin-job-pages">
        //       <AdminJobList />
        //     </RoleBasedGuard>
        //   ),
        // },
        // {
        //   path: 'admin/career-track/new',
        //   element: (
        //     <RoleBasedGuard roles={['company']} slugCode="admin-career-track">
        //       <AdminCareerTrack/>
        //     </RoleBasedGuard>
        //   )
        // },
        // {
        //   path: 'admin/career-track/list',
        //   element: (
        //     <RoleBasedGuard roles={['company']} slugCode="admin-career-track">
        //       <CareerTrackList/>
        //     </RoleBasedGuard>
        //   )
        // },
        // {
        //   path: 'admin/career-track/edit/:id',
        //   element: (
        //     <RoleBasedGuard roles={['company']} slugCode="admin-career-track">
        //       <EditCareerTrack/>
        //     </RoleBasedGuard>
        //   )
        // },
        // {
        //   path: 'admin/career-track/edit-pathway/:id',
        //   element: (
        //     <RoleBasedGuard roles={['company']} slugCode="admin-career-track">
        //       <EditCareerPathway/>
        //     </RoleBasedGuard>
        //   )
        // },
        // {
        //   path: 'admin/university/onboarding',
        //   element: (
        //     <RoleBasedGuard roles={['company']} slugCode="admin-uni-onboarding">
        //       <UniOnboarding />
        //     </RoleBasedGuard>
        //   ),
        // },
        // { path: 'user', element: <User /> },
        // {path: 'openings', element: <JobView />},
        // {path: 'openings/view/:id',  element: <SingleJobView /> },
        // {path: 'events', element: <EventsView />},
        // {path: 'events/view/:id', element: <SingleEventView />}
      ],
    },

    // {
    //   path: '/recruiter/dashboard',
    //   element: <RecruiterDashboardLayout />,
    //   children: [
    //     { path : 'app', element: <RecruiterDashboardApp />},
    //   ],
    // },
    // {
    //   path: '/university/staff/dashboard',
    //   element: <UniversityStaffDashboardLayout />,
    //   children: [
    //     { path : 'app', element: <UniversityStaffDashboardApp />},
    //     {path : 'add/student', element: <UniversityStaffAddStudent />},
    //     {path: 'groups', element: < GroupsView />}
    //   ],
    // },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoon /> },
        // { path: 'maintenance', element: <Maintenance /> },
        // { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        { path: 'un/:slug', element: <UniOnboardingACQ /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   // element: <MainLayout />,
    //   element: <LogoOnlyLayout />,
    //   children: [
    //     // { element: <HomePage />, index: true },
    //     // {element: <Login />, index: true},
    //     // { path: 'about-us', element: <About /> },
    //     // { path: 'contact-us', element: <Contact /> },
    //     // { path: 'faqs', element: <Faqs /> },
    //   ],
    // },
    {
      path: '/',
      // element: <MainLayout />,
      element: <Navigate to="/auth/login" replace />,
      children: [
        // { element: <HomePage />, index: true },
        // {element: <Login />, index: true},
        // { path: 'about-us', element: <About /> },
        // { path: 'contact-us', element: <Contact /> },
        // { path: 'faqs', element: <Faqs /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const StudentLogin = Loadable(lazy(() => import('../pages/auth/StudentLogin')));
const UniversityLogin = Loadable(lazy(() => import('../pages/auth/UniversityLogin')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewUserResetPassword = Loadable(lazy(() => import('../pages/auth/NewUserResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const SetupPassword = Loadable(lazy(() => import('../pages/auth/SetupPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const UniversitySignup = Loadable(lazy(() => import('../pages/auth/UniversitySignup')));


// University Onboarding
const UniOnboardingACQ = Loadable(lazy(() => import('../pages/universityOnboard/UniOnboarding')));

// DASHBOARD

/// STUDENT
// GENERAL
const StudentDashboard = Loadable(lazy(() => import('../pages/student/Dashboard')));
const Onboarding = Loadable(lazy(() => import('../pages/student/Onboarding')));
const StudentSocial = Loadable(lazy(() => import('../pages/student/Social')));
const Jobs = Loadable(lazy(() => import('../pages/student/Jobs/Jobs')));
const JobsApplied = Loadable(lazy(() => import('../pages/student/Jobs/JobApplication')));
const StudentJobsView = Loadable(lazy(() => import('../pages/student/Jobs/JobView')));
const Internships = Loadable(lazy(() => import('../pages/student/Jobs/Internships')));
const InternshipView = Loadable(lazy(() => import('../pages/student/Jobs/JobView')));
const HiringDrive = Loadable(lazy(() => import('../pages/student/HiringDrive/HiringDrive')));

const Events = Loadable(lazy(() => import('../pages/student/Events/Events')));
const EventView = Loadable(lazy(() => import('../pages/student/Events/EventView')));
const Opportunities = Loadable(lazy(() => import('../pages/student/Opportunities')));
const OpportunityView = Loadable(lazy(() => import('../pages/student/OpportunityView')));

const StudentFeed = Loadable(lazy(() => import('../pages/student/Feed/Feed')));
const StudentFeedPostView = Loadable(lazy(() => import('../pages/student/Feed/FeedPostView')));

const StudentWorkshops = Loadable(lazy(() => import('../pages/student/Workshops/Workshops')));
const StudentWorkshopPostView = Loadable(lazy(() => import('../pages/student/Workshops/WorkshopPostView')));

const CareerTrackDashboard = Loadable(lazy(() => import('../pages/student/CareerTrack/CareerTrackDashboard')));
const InterviewQA = Loadable(lazy(() => import('../pages/student/CareerTrack/InterviewQA')));
const MockInterview = Loadable(lazy(() => import('../pages/student/CareerTrack/MockInterview/MockInterview')));
const MockInterviewResults = Loadable(lazy(() => import('../pages/student/CareerTrack/MockInterview/Results')));
const MockInterviewResultView = Loadable(lazy(() => import('../pages/student/CareerTrack/MockInterview/ResultView')));
const MbtiTest = Loadable(lazy(() => import('../pages/student/CareerTrack/MBTI/MbtiTest')));
const MbtiResults = Loadable(lazy(() => import('../pages/student/CareerTrack/MBTI/Results')));
const MbtiResultView = Loadable(lazy(() => import('../pages/student/CareerTrack/MBTI/ResultView')));

const ResumeList = Loadable(lazy(() => import('../pages/student/Resume/ListResumes')));
const ResumeEdit = Loadable(lazy(() => import('../pages/student/Resume/EditResume')));

const CareerExplorer = Loadable(lazy(() => import('../pages/student/CareerExplorer/CareerExplorer')));
const CareerExplorerQuestions = Loadable(lazy(() => import('../pages/student/CareerExplorer/CareerQuestions')));
const CareerExplorerHistory = Loadable(lazy(() => import('../pages/student/CareerExplorer/TestHistory')));
const SuggestedPosition = Loadable(lazy(() => import('../pages/student/CareerExplorer/SuggestedPosition')));

const CTRoadmap = Loadable(lazy(() => import('../pages/student/CareerTrack/Roadmap/RoadmapHome')));

const Tests = Loadable(lazy(() => import('../pages/student/Test/Tests')));
const StudentTest = Loadable(lazy(() => import('../pages/student/Test/StudentTest')));
const TestInstruction = Loadable(lazy(() => import('../pages/student/Test/StudentTestInstruction')));

// USER
const StudentProfile = Loadable(lazy(() => import('../pages/student/user/StudentProfile')));
const EditStudentProfile = Loadable(lazy(() => import('../pages/student/user/EditStudentProfile')));
const StudentUserAccount = Loadable(lazy(() => import('../pages/student/user/UserAccount')));

/// Univeristy
// GENERAL
const UniversityAddStudent = Loadable(lazy(() => import('../pages/university/StudentManagement/UniversityAddStudent')));
const StudentActivity = Loadable(lazy(() => import('../pages/university/StudentManagement/StudentActivity')));
const StudentDetails = Loadable(lazy(() => import('../pages/university/StudentManagement/StudentDetails')));
const UniversityBulkAddStudent = Loadable(lazy(() => import('../pages/university/StudentManagement/BulkImportStudent')));
const AcademicStructure = Loadable(lazy(() => import('../pages/university/StudentManagement/AcademicStructure')));
const CourseManager = Loadable(lazy(() => import('../pages/university/StudentManagement/AcademicStManager/CourseManager')));
const StudentProgression = Loadable(lazy(() => import('../pages/university/StudentManagement/AcademicStManager/StudentProgression')));

const GroupsView = Loadable(lazy(() => import('../pages/university/Groups/GroupsView')));
const NewGroup = Loadable(lazy(() => import('../pages/university/Groups/NewGroup')));
const UniversityEditStudent = Loadable(
  lazy(() => import('../pages/university/StudentManagement/UniversityAddStudent'))
);
const UniversityStaffDashboard = Loadable(lazy(() => import('../pages/university/Dashboard')));
const HiringDriveUniversity = Loadable(lazy(() => import('../pages/university/HiringDrive/HiringDrive')));
const NewHiringDriveUniversity = Loadable(lazy(() => import('../pages/university/HiringDrive/NewHiringDrive')));
const EditHiringDrive = Loadable(lazy(() => import('../pages/university/HiringDrive/EditHiringDrive')));

const UniversityJobs = Loadable(lazy(() => import('../pages/university/Jobs/Jobs')));
const JobsApproval = Loadable(lazy(() => import('../pages/university/Jobs/JobsApproval')));

const RHCompanyList = Loadable(lazy(() => import('../pages/university/RecruiterHub/RHCompanyList')));
const RHMessages = Loadable(lazy(() => import('../pages/university/RecruiterHub/RHMessages')));

const UniEvents = Loadable(lazy(() => import('../pages/university/Events/Events')));
const UniEventsView = Loadable(lazy(() => import('../pages/university/Events/EventView')));
const UniEventForm = Loadable(lazy(() => import('../pages/university/Events/EventForm')));
const UniEventPostEdit = Loadable(lazy(() => import('../pages/university/Events/EditEvent')));

const UniFeed = Loadable(lazy(() => import('../pages/university/Feed/Feed')));
const UniFeedNewPost = Loadable(lazy(() => import('../pages/university/Feed/FeedNewPost')));
const UniFeedEditPost = Loadable(lazy(() => import('../pages/university/Feed/FeedPostEdit')));
const UniFeedPostView = Loadable(lazy(() => import('../pages/university/Feed/FeedPostView')));

const UniWorkshops = Loadable(lazy(() => import('../pages/university/Workshops/Workshops')));
const UniWorkshopPostView = Loadable(lazy(() => import('../pages/university/Workshops/WorkshopPostView')));
const UniWorkshopNewPost = Loadable(lazy(() => import('../pages/university/Workshops/NewWorkshop')));
const UniWorkshopEditPost = Loadable(lazy(() => import('../pages/university/Workshops/EditWorkshop')));

const UniStudentTest = Loadable(lazy(() => import('../pages/university/Tests/UniversityTest')));
const UniStudentTestView = Loadable(lazy(() => import('../pages/university/Tests/TestView')));
const UniStudentDetailedResult = Loadable(lazy(()=>import('../pages/university/Tests/DetailedResult')))
const UniScheduleTest = Loadable(lazy(()=>import('../pages/university/Tests/ScheduleTest')))
const TestCreated = Loadable(lazy(()=>import('../pages/university/Tests/TestCreated')))

// USER
const UniveristyUserAccount = Loadable(lazy(() => import('../pages/university/user/UserAccount')));

/// COMPANY
const CompanyDashboard = Loadable(lazy(() => import('../pages/company/CompanyDashboard')));
const AddJob = Loadable(lazy(() => import('../pages/company/AddJob')));
const JobsList = Loadable(lazy(() => import('../pages/company/JobsList')));
const EditJob = Loadable(lazy(() => import('../pages/company/EditJob')));

const CompanyUserAccount = Loadable(lazy(() => import('../pages/company/user/CompanyAccount')));
const Applicants = Loadable(lazy(() => import('../pages/company/Applicant1')));
const ApplicantsView = Loadable(lazy(() => import('../pages/company/Applicant1')));

// Admin
const AdminCreateJob = Loadable(lazy(() => import('../pages/company/AdminCreateJob')));
const AdminCreateTest = Loadable(lazy(() => import('../pages/company/AddTest')));
const AdminJobList = Loadable(lazy(() => import('../pages/company/AdminJobList')));
const AdminCareerTrack = Loadable(lazy(() => import('../pages/company/AddCareerTrack')));
const CareerTrackList = Loadable(lazy(() => import('../pages/company/CareerTrackList')));
const EditCareerTrack = Loadable(lazy(() => import('../pages/company/EditCareerTrack')));
const EditCareerPathway = Loadable(lazy(() => import('../pages/company/EditCareerPathway')));
const UniOnboarding = Loadable(lazy(() => import('../pages/company/admin/uniOnboarding')));
const TestList = Loadable(lazy(() => import('../pages/company/TestList')));
const AddQuestions = Loadable(lazy(() => import('../pages/company/AddQuestion')));
const TestQuestionList = Loadable(lazy(() => import('../pages/company/TestQuestionList')));
const QuestionList = Loadable(lazy(()=>import('../pages/company/QuestionList')))
const OptionsList = Loadable(lazy(() => import('../pages/company/OptionsList')));
const AdminTestEdit = Loadable(lazy(() => import('../pages/company/TestEdit')));
const AdminTestQuestionEdit = Loadable(lazy(() => import('../pages/company/TestQuestionEdit')));
const TestBuilder = Loadable(lazy(() => import('../pages/company/TestBuilder')));

// GENERAL
const Crossing = Loadable(lazy(() => import('../pages/dashboard/Crossing')));
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceProductEdit = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductEdit')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
