// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_STUDENT = '/student';
const ROOTS_UNIVERSITY = '/university';
const ROOTS_COMPANY = '/company';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    crossing: path(ROOTS_DASHBOARD, '/crossing'),
    // app: path(ROOTS_DASHBOARD, '/app'),
    // ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    // analytics: path(ROOTS_DASHBOARD, '/analytics'),
    // banking: path(ROOTS_DASHBOARD, '/banking'),
    // booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_STUDENT = {
  root: ROOTS_STUDENT,
  general: {
    app: path(ROOTS_STUDENT, '/dashboard/app'),
    social: path(ROOTS_STUDENT, '/dashboard/social'),
    onboarding: path(ROOTS_STUDENT, '/dashboard/setup/onboarding'),

    jobs: path(ROOTS_STUDENT, '/dashboard/jobs'),
    jobsApplied: path(ROOTS_STUDENT, '/dashboard/jobs/applied'),
    internships: path(ROOTS_STUDENT, '/dashboard/internships'),
    hiringDrive: path(ROOTS_STUDENT, '/dashboard/hiring-drive'),

    jobView: (id) => path(ROOTS_STUDENT, `/dashboard/jobs/view/${id}`),
    internshipView: (id) => path(ROOTS_STUDENT, `/dashboard/internships/view/${id}`),
    hiringDriveView: (id) => path(ROOTS_STUDENT, `/dashboard/hiring-drive/view/${id}`),

    events: path(ROOTS_STUDENT, '/dashboard/events/view'),
    eventView: (id) => path(ROOTS_STUDENT, `/dashboard/events/view/${id}`),

    opportunities: path(ROOTS_STUDENT, '/dashboard/opportunities/view'),
    opportunityView: (id) => path(ROOTS_STUDENT, `/dashboard/opportunities/view/${id}`),

    feed: path(ROOTS_STUDENT, '/dashboard/feed/view'),
    feedPostView: (id) => path(ROOTS_STUDENT, `/dashboard/feed/view/${id}`),

    workshops: path(ROOTS_STUDENT, '/dashboard/workshops/view'),
    workshopPostView: (id) => path(ROOTS_STUDENT, `/dashboard/workshops/view/${id}`),

    careerTrack: path(ROOTS_STUDENT, '/dashboard/career-track/home'),
    InterviewQA: path(ROOTS_STUDENT, '/dashboard/career-track/interview-qa'),

    careerExplorer: path(ROOTS_STUDENT, '/dashboard/career-explorer'),
    careerExplorerQuestions: path(ROOTS_STUDENT, '/dashboard/career-explorer/career-questions'),
    // careerExplorerSuggestion:(id)=> path(ROOTS_STUDENT,`/dashboard/career-explorer/suggested-position/${id}`),
    careerExplorerSuggestion: path(ROOTS_STUDENT, '/dashboard/career-explorer/suggested-position'),
    careerExplorerHistory: path(ROOTS_STUDENT, '/dashboard/career-explorer/past-results'),

    mockInterview: path(ROOTS_STUDENT, '/dashboard/career-track/mock-interview'),
    mockInterviewResults: path(ROOTS_STUDENT, '/dashboard/career-track/mock-interview/results'),
    mockInterviewResultView: (id) => path(ROOTS_STUDENT, `/dashboard/career-track/mock-interview/results/${id}`),
    mbtiTest: path(ROOTS_STUDENT, '/dashboard/career-track/mbti-test'),
    mbtiResults: path(ROOTS_STUDENT, '/dashboard/career-track/mbti-test/results'),
    mbtiResultView: (id) => path(ROOTS_STUDENT, `/dashboard/career-track/mbti-test/results/${id}`),

    ctroadmap: path(ROOTS_STUDENT, '/dashboard/career-track/roadmap/'),
  },

  user: {
    // root: path(ROOTS_STUDENT, '/dashboard/user'),
    // new: path(ROOTS_STUDENT, '/dashboard/user/new'),
    // list: path(ROOTS_STUDENT, '/dashboard/user/list'),
    // cards: path(ROOTS_STUDENT, '/dashboard/user/cards'),
    profile: path(ROOTS_STUDENT, '/dashboard/user/profile'),
    editProfile: path(ROOTS_STUDENT, '/dashboard/user/profile/edit'),
    account: path(ROOTS_STUDENT, '/dashboard/user/account'),
    // edit: (name) => path(ROOTS_STUDENT, `/dashboard/user/${name}/edit`),
    // demoEdit: path(ROOTS_STUDENT, `/dashboard/user/reece-chung/edit`),

    resume: path(ROOTS_STUDENT, '/dashboard/user/resume'),
    editResume: (id) => path(ROOTS_STUDENT, `/dashboard/user/resume/edit/${id}`),

    test: path(ROOTS_STUDENT, '/dashboard/user/test'),
  },
};

export const PATH_UNIVERSITY = {
  root: ROOTS_UNIVERSITY,
  general: {
    app: path(ROOTS_UNIVERSITY, '/dashboard/app'),
    addStudent: path(ROOTS_UNIVERSITY, '/dashboard/add/student'),
    editStudent: (id) => path(ROOTS_UNIVERSITY, `/dashboard/edit/student/${id}`),
    bulkAddStudent: path(ROOTS_UNIVERSITY, '/dashboard/student/bulk-add'),
    academicStructureManager: path(ROOTS_UNIVERSITY, '/dashboard/student/academic-structure'),
    courseManager: path(ROOTS_UNIVERSITY, '/dashboard/student/academic-structure/course-manager'),
    studentProgression : path(ROOTS_UNIVERSITY, '/dashboard/student/academic-structure/student-progression'),
    groups: path(ROOTS_UNIVERSITY, '/dashboard/groups'),
    newGroup: path(ROOTS_UNIVERSITY, '/dashboard/groups/new'),
    studentActivity: path(ROOTS_UNIVERSITY, '/dashboard/student-activity'),
    studentDetails: path(ROOTS_UNIVERSITY, '/dashboard/student-details'),

    jobs: path(ROOTS_UNIVERSITY, '/dashboard/jobs/list'),
    jobsApproval: path(ROOTS_UNIVERSITY, '/dashboard/jobs/approval'),

    hiringDrive: path(ROOTS_UNIVERSITY, '/dashboard/hiring-drive'),
    newHiringDrive: path(ROOTS_UNIVERSITY, '/dashboard/hiring-drive/new'),
    editHiringDrive: (id) => path(ROOTS_UNIVERSITY, `/dashboard/hiring-drive/${id}/edit`),

    rhCompanyList: path(ROOTS_UNIVERSITY, '/dashboard/recruiterhub/company/list'),
    rhMessages: path(ROOTS_UNIVERSITY, '/dashboard/recruiterhub/messages'),

    events: path(ROOTS_UNIVERSITY, '/dashboard/events/view'),
    eventView: (id) => path(ROOTS_UNIVERSITY, `/dashboard/events/view/${id}`),
    eventForm: path(ROOTS_UNIVERSITY, '/dashboard/events/new'),
    eventEditPost: (id) => path(ROOTS_UNIVERSITY, `/dashboard/events/edit/${id}`),

    feed: path(ROOTS_UNIVERSITY, '/dashboard/feed/view'),
    feedPostView: (id) => path(ROOTS_UNIVERSITY, `/dashboard/feed/view/${id}`),
    feedNewPost: path(ROOTS_UNIVERSITY, '/dashboard/feed/new'),
    feedPostEdit: (id) => path(ROOTS_UNIVERSITY, `/dashboard/feed/edit/${id}`),

    workshops: path(ROOTS_UNIVERSITY, '/dashboard/workshops/view'),
    workshopPostView: (id) => path(ROOTS_UNIVERSITY, `/dashboard/workshops/view/${id}`),
    workshopsNewPost: path(ROOTS_UNIVERSITY, '/dashboard/workshops/new'),
    workshopsEditPost: (id) => path(ROOTS_UNIVERSITY, `/dashboard/workshops/edit/${id}`),

    testsList: path(ROOTS_UNIVERSITY, '/dashboard/tests/list/'),
    testView: (id) => path(ROOTS_UNIVERSITY, `/dashboard/tests/view/${id}`),
    detailedResult: path(ROOTS_UNIVERSITY, '/dashboard/tests/student/result/'),
    scheduleTest: path(ROOTS_UNIVERSITY, '/dashboard/tests/schedule/'),
    testCreated:path(ROOTS_UNIVERSITY,'/dashboard/tests/schedule-result/')
  },
  user: {
    account: path(ROOTS_UNIVERSITY, '/dashboard/user/account'),
  },
};

export const PATH_COMPANY = {
  root: ROOTS_COMPANY,
  general: {
    app: path(ROOTS_COMPANY, '/dashboard/app'),
    newJobPosting: path(ROOTS_COMPANY, '/dashboard/jobs/new/'),
    JobsList: path(ROOTS_COMPANY, '/dashboard/jobs/list/'),
    JobView: (name) => path(ROOTS_COMPANY, `/dashboard/jobs/${name}`),
    JobEdit: (name) => path(ROOTS_COMPANY, `/dashboard/jobs/edit/${name}`),

    JobApplicants: path(ROOTS_COMPANY, `/dashboard/jobs/list/`),
    JobApplicant1: (id) => path(ROOTS_COMPANY, `/dashboard/jobs/applicants/${id}`),
  },
  admin: {
    JobCreate: path(ROOTS_COMPANY, '/dashboard/admin/jobs/new'),
    JobList: path(ROOTS_COMPANY, '/dashboard/admin/jobs/list'),
    CareerTrackCreate: path(ROOTS_COMPANY, '/dashboard/admin/career-track/new'),
    CareerTrackList: path(ROOTS_COMPANY, '/dashboard/admin/career-track/list'),
    editCareerTrack: (id) => path(ROOTS_COMPANY, `/dashboard/admin/career-track/edit/${id}`),
    editCareerPathway: (id) => path(ROOTS_COMPANY, `/dashboard/admin/career-track/edit-pathway/${id}`),
    uniOnboarding: path(ROOTS_COMPANY, '/dashboard/admin/university/onboarding'),
    createTest: path(ROOTS_COMPANY, '/dashboard/admin/test/new'),
    testList: path(ROOTS_COMPANY, '/dashboard/admin/test/list'),
    testQuestionList: (id) => path(ROOTS_COMPANY, `/dashboard/admin/test-question/list/${id}`),
    questionList: path(ROOTS_COMPANY, '/dashboard/admin/test-question/list'),
    // addQuestion: (id) => path(ROOTS_COMPANY, `/dashboard/admin/test/question/${id}`),
    addQuestion: path(ROOTS_COMPANY, '/dashboard/admin/test/question/new'),
    optionsList: (id) => path(ROOTS_COMPANY, `/dashboard/admin/test-question-options/list/${id}`),
    editTest: (id) => path(ROOTS_COMPANY, `/dashboard/admin/test/edit/${id}`),
    editQuestion:(id)=>path(ROOTS_COMPANY,`/dashboard/admin/test-question/edit/${id}`),
    testBuilder:path(ROOTS_COMPANY,'/dashboard/admin/test-builder')
  },
  user: {
    CompanyAccount: path(ROOTS_COMPANY, '/dashboard/user/account'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
export const PATH_STUDENT_TEST = '/student-test';
export const PATH_STUDENT_TEST_INSTRUCTION = '/student-test-instruction';
